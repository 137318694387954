nav {
  /* general */
  .navbar-toggler {
    color: $light;
    border-color: $light;
    border-style: solid;
    border-width: 1px;
  }

  /* alternative sizes */
  &.navbar-xs {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: .9em;
    .navbar-brand, .form-control {
      font-size: inherit !important;
    }
  }
  &.navbar-sm {
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: .95em;
    .navbar-brand, .form-control {
      font-size: inherit !important;
    }
  }
  &.navbar-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .navbar-brand, .form-control {
      font-size: inherit !important;
    }
  }
  &.navbar-lg {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1.125rem;
  }
  &.navbar-xl {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.25rem;
  }

  .navbar-language-dropdown.dropdown-menu {
    min-width: 7.5rem;
    background-color: $body-bg !important;
  }

  /* light/dark modes */
  &.navbar-light {
    a {
      &.nav-link {
        .active > & {
          color: rgba($white, .95) !important;
        }
      }
      &.nav-link, &.dropdown-item {
        color: rgba($white, .8) !important;;
      }
    }
    .navbar-brand, .navbar-brand:hover, a.nav-item:hover {
      color: $white !important;
    }
    .navbar-toggler {
      color: rgba($white, .35) !important;
      border-color: rgba($white, .15) !important;
    }
    .navbar-language-dropdown.dropdown-menu {
      background-color: $body-night-bg !important;
    }
  }
  &.navbar-dark {
    a {
      &.nav-link {
        .active > & {
          color: rgba($black, .95) !important;
        }
      }
      &.nav-link, &.dropdown-item {
        color: rgba($black, .8) !important;;
      }
    }
    .navbar-brand, .navbar-brand:hover, a.nav-item:hover {
      color: $black !important;
    }
    .navbar-toggler {
      color: rgba($black, .35) !important;
      border-color: rgba($black, .15) !important;
    }
    .navbar-language-dropdown.dropdown-menu {
      background-color: $body-bg !important;
    }
  }

}

/*

nav.navbar-dark .active a,
nav.navbar-dark .navbar-brand,
nav.navbar-dark .navbar-brand:hover{
  color: $black !important;
}
nav.navbar-light .active a,
nav.navbar-light .navbar-brand,
nav.navbar-light .navbar-brand:hover {
  color: $white !important;
}


nav.navbar-light a.nav-link {
  color: rgba($light, 0.7) !important;
}
nav.navbar-light .show a,
nav.bg-dark .show a,
nav.bg-dark a.nav-link:hover,
nav.navbar-light a.nav-link:hover {
  color: rgba($light, 0.9) !important;
}
nav.navbar-light a.nav-link.disabled {
  color: rgba($light, 0.35) !important;
}
nav.navbar-dark a.nav-link {
  color: rgba($dark, 0.7) !important;
}
nav.navbar-dark .show a,
nav.navbar-dark a.nav-link:hover {
  color: rgba($dark, 0.9) !important;
}

nav.navbar-dark a.nav-link.disabled {
  color: rgba($dark, 0.35) !important;
}

nav .form-control {
  background-color: $dark-35 !important;
  border: 1px solid $dark-55 !important;
}
nav .form-control:focus {
  background-color: $dark !important;
  border: 1px solid $light-35 !important;
}
nav.bg-dark .form-control {
  background-color: $gray-800 !important;
  border: 1px solid $light-35 !important;
}
nav.bg-dark .form-control:focus {
  background-color: $dark !important;
  border: 1px solid $light-35 !important;
}

*/

/*

.navbar-light .navbar-nav .nav-link {
  color: rgb(64, 64, 64);
}

.navbar-brand {
  font-size: 1rem !important;
}

.navbar-language-dropdown.dropdown-menu {
  min-width: 7.5rem;
}

.navbar-brand.navbar-logo {
  background: url('../images/acme_logo_320x100.png');
  background-size: cover;
  width: 160px;
  height: 48px;
}
*/



@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/lato-v24-latin-regular.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/open-sans-v34-latin-regular.eot);
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url(../fonts/open-sans-v34-latin-regular.eot?#iefix) format('embedded-opentype'),
  url(../fonts/open-sans-v34-latin-regular.woff2) format('woff2'),
  url(../fonts/open-sans-v34-latin-regular.woff) format('woff'),
  url(../fonts/open-sans-v34-latin-regular.ttf) format('truetype'),
  url(../fonts/open-sans-v34-latin-regular.svg#OpenSans) format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://yoursite.domain/wp-content/uploads/open-sans-v18-latin-600.eot);
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
  url(../fonts/open-sans-v34-latin-600.eot?#iefix) format('embedded-opentype'),
  url(../fonts/open-sans-v34-latin-600.woff2) format('woff2'),
  url(../fonts/open-sans-v34-latin-600.woff) format('woff'),
  url(../fonts/open-sans-v34-latin-600.ttf) format('truetype'),
  url(../fonts/open-sans-v34-latin-600.svg#OpenSans) format('svg');
}
input[type=color].form-control {
  height: $input-height;
  padding: 0 !important;
  border: none;
  background-color: transparent !important;
}

/* addons */
.custom-file .form-control-file {
  display: none;
}
.custom-radio, .custom-checkbox {
  .custom-control-label::before {
    background-color: $form-control-background-color;
    border: 1px solid $form-control-border-color;
  }
}
.custom-file-label {
  background-color: $form-control-background-color;
  border: 1px solid $form-control-border-color;
}
.custom-file-label::after {
  background-color: $form-control-prefix-background-color;
  border: 1px solid $form-control-border-color;
  color: $form-control-prefix-color;
}
.input-group {
  .input-group-text {
    color: $form-control-prefix-color;
    background-color: $form-control-prefix-background-color;
    border: 1px solid $form-control-border-color;
  }
}
/* addons */


.form-control {
  border: 1px solid $form-control-border-color;
  background-color: $form-control-background-color;
  color: $form-control-color;
  &:focus > option:hover {
    background-color: $form-control-background-color !important;
    color: $bs-select-option-hover-color !important;
  }
  &:disabled {
    color: $form-control-disabled-color !important;
    background-color: $form-control-disabled-background-color !important;
  }
  &.form-control-plaintext {
    background-color: $form-control-background-color !important;
    border: 1px solid $form-control-border-color !important;
    color: $form-control-disabled-color !important;
  }
}

/* night mode */
.adjust-color-night {
  /* addons */
  .custom-radio, .custom-checkbox {
    .custom-control-label::before {
      /*background-color: $form-control-night-background-color;*/
      border: 1px solid $form-control-night-border-color;
    }
  }
  .custom-file-label {
    background-color: $form-control-night-background-color;
    border: 1px solid $form-control-night-border-color;
  }
  .custom-file-label::after {
    background-color: $form-control-night-prefix-background-color;
    border: 1px solid $form-control-night-border-color;
    color: $form-control-night-prefix-color;
  }
  .input-group {
    .input-group-text {
      color: $form-control-night-prefix-color;
      background-color: $form-control-night-prefix-background-color;
      border: 1px solid $form-control-night-border-color;
    }
  }

  .form-control {
    border: 1px solid $form-control-night-border-color !important;
    background-color: $form-control-night-background-color !important;
    color: $form-control-night-color !important;
    &:focus > option:hover {
      background-color: $bs-select-night-option-hover-bg !important;
      color: $bs-select-night-option-hover-color !important;
    }
    &:disabled {
      color: $form-control-night-disabled-color !important;
      background-color: $form-control-night-disabled-background-color !important;
    }
    &.form-control-plaintext {
      background-color: $form-control-night-background-color !important;
      border: 1px solid $form-control-night-border-color !important;
      color: $form-control-night-disabled-color !important;
    }
  }

}
.btn {
  &.btn-link {
    text-decoration: none !important;
    color: $primary;
  }

  &.btn-circle {
    border-radius: 50% !important;
    width: 32px;
    height: 32px;
    padding: .2em 0 0 0;
    color: $black;
    &:hover {
      color: $white;
    }
  }

  /* color: $white; */

}
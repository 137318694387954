$body-bg: #ffffff;
$body-color: #10140c;

$primary: #52663d;
$secondary: #899997;
$success: #1e9933;
$warning: #ff8800;
$info: #17a2b8;
$danger: #CE151A;
$light: lighten($primary, 25%);
$dark: darken($primary, 25%);

$purple: #995378;
$sundown: #FED26F;

/*
$body-color: #332618;
$primary: #805626;
$secondary: #91A2A0;
$success: #1e9933;
$warning: #ff8800;
$info: #17a2b8;
$danger: #CE151A;
$light: #f0b46e;
$dark: #332618;
*/

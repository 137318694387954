/* day */
$form-control-color: $body-color;
$form-control-border-color: rgba($body-color, .15);
$form-control-background-color: rgba($body-night-bg, .05);

$form-control-disabled-color: rgba($form-control-color, .75);
$form-control-disabled-background-color: rgba($body-night-bg, .1);

$form-control-prefix-color: rgba($body-color, .95);
$form-control-prefix-background-color: rgba($body-night-bg, .25);

$bs-select-button-hover-bg: $body-bg;

$bs-select-option-hover-color: $black;
$bs-select-option-hover-bg: rgba($primary, .45);
$bs-select-option-hover-border-color: rgba($primary, .45);
$bs-select-option-selected-bg: rgba($primary, .25);

/* night */
$form-control-night-color: $body-night-color;
$form-control-night-border-color: rgba($body-night-color, .15);
$form-control-night-background-color: rgba($body-bg, .05);

$form-control-night-disabled-color: rgba($form-control-night-color, .75);
$form-control-night-disabled-background-color: rgba($body-bg, .1);

$form-control-night-prefix-color: rgba($body-night-color, .95);
$form-control-night-prefix-background-color: rgba($body-bg, .25);

$bs-select-night-button-hover-bg: $body-night-bg;

$bs-select-night-option-hover-color: $white;
$bs-select-night-option-hover-bg: rgba($primary, .45);
$bs-select-night-option-hover-border-color: rgba($primary, .45);
$bs-select-night-option-selected-bg: rgba($primary, .25);

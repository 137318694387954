.quote-card {
  background: transparent;
  color: $body-color;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  min-height: 120px;
}
.quote-card p {
  font-size: 125%;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 85%;
}
.quote-card cite {
  margin-top: 1em;
  display: block;
  font-weight: 200;
  opacity: 0.8;
}

.quote-card.quote-card-secondary {
  background: $secondary-35;
  color: $secondary;
  /* box-shadow: 0 1px 2px rgba(34, 34, 34, 0.12), 0 2px 4px rgba(34, 34, 34, 0.24); */
}
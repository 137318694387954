h1 {
  font-size: 150% !important;
  letter-spacing: 3px;
}
h2 {
  font-size: 140% !important;
  letter-spacing: 2.5px;
}
h3 {
  font-size: 125% !important;
  letter-spacing: 1.75px;
}
h4 {
  font-size: 120% !important;
  letter-spacing: 1.5px;
}
h5 {
  font-size: 110% !important;
  letter-spacing: 0.5px;
}

h1, h2, h3, h4, h5 {
  font-family: 'Lato' !important;
  font-weight: normal !important;
}

.text-lato {
  font-family: 'Lato' !important;
}

.title-image {
  height: 250px;
  background: url('../images/title.png') no-repeat center center;
  background-size: cover
}

.profile-image {
  height: 280px;
  width: 280px;
  background: url('../images/susanne-schuetze.jpg') no-repeat center center;
  background-size: cover;
}

.image-therapy {
  height: 353px;
  width: 280px;
  background-size: auto !important;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.image-therapy.image-therapy-1 {
  background-image: url('../images/sexualtherapie.png');
}

.image-therapy.image-therapy-2 {
  background-image: url('../images/paartherapie.png');
}

.image-therapy.image-therapy-3 {
  background-image: url('../images/koerperpsychotherapie.png');
}

.navbar-brand {
  font-size: 1rem !important;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.5);
}

@media (min-width: 576px) {
  .title-image {
    height: 430px;
  }
  .profile-image {
    width: auto;
    height: 510px;
    background-size: cover
  }

  h1 {
    font-size: 180% !important;
    letter-spacing: 4px;
  }
  h2 {
    font-size: 165% !important;
    letter-spacing: 2.5px;
  }
  h3 {
    font-size: 150% !important;
    letter-spacing: 2px;
  }
  h4 {
    font-size: 135% !important;
    letter-spacing: 1.75px;
  }
  h5 {
    font-size: 120% !important;
    letter-spacing: 0.8px;
  }

}

@media (min-width: 768px) {
  .title-image {
    height: 520px;
  }
  .profile-image {
    height: 350px;
  }

  h1 {
    font-size: 250% !important;
    letter-spacing: 5px;
  }
  h2 {
    font-size: 200% !important;
    letter-spacing: 3px;
  }
  h3 {
    font-size: 175% !important;
    letter-spacing: 2.5px;
  }
}

@media (min-width: 992px) {
  .title-image {
    height: 650px;
  }
  .profile-image {
    height: 370px;
  }
}

@media (min-width: 1200px) {
  .title-image {
    height: 786px;
  }
  .profile-image {
    height: 445px;
  }
}

.footer {
  .nav-link {
    padding: .5rem 1rem;
  }
}

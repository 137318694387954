#cookie-consent {
  /*display: none;*/
  padding: 0;
  outline: 1px solid $cookie-alert-outline;
  border-top: 1px solid $cookie-alert-border-top;
  background: $cookie-alert-gradient-bottom;
  background: -moz-linear-gradient(top, $cookie-alert-gradient-top 0%, $cookie-alert-gradient-bottom 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $cookie-alert-gradient-top), color-stop(100%,$cookie-alert-gradient-bottom));
  background: -webkit-linear-gradient(top, $cookie-alert-gradient-top 0%,$cookie-alert-gradient-bottom 100%);
  background: -o-linear-gradient(top, $cookie-alert-gradient-top 0%,$cookie-alert-gradient-bottom 100%);
  background: -ms-linear-gradient(top, $cookie-alert-gradient-top 0%,$cookie-alert-gradient-bottom 100%);
  background: linear-gradient(to bottom, $cookie-alert-gradient-top 0%,$cookie-alert-gradient-bottom 100%);
}

#cookie-consent a {
  font-weight: bold;
}
/** alpha channel colors */
$primary-15: rgba($primary,.15);
$secondary-15: rgba($secondary,.15);
$success-15: rgba($success,.15);
$info-15: rgba($info,.15);
$warning-15: rgba($warning,.15);
$danger-15: rgba($danger,.15);
$light-15: rgba($light,.15);
$dark-15: rgba($dark,.15);

$primary-35: rgba($primary,.35);
$secondary-35: rgba($secondary,.35);
$success-35: rgba($success,.35);
$info-35: rgba($info,.35);
$warning-35: rgba($warning,.35);
$danger-35: rgba($danger,.35);
$light-35: rgba($light,.35);
$dark-35: rgba($dark,.35);

$primary-55: rgba($primary,.55);
$secondary-55: rgba($secondary,.55);
$success-55: rgba($success,.55);
$info-55: rgba($info,.55);
$warning-55: rgba($warning,.55);
$danger-55: rgba($danger,.55);
$light-55: rgba($light,.55);
$dark-55: rgba($dark,.55);

$primary-75: rgba($primary,.75);
$secondary-75: rgba($secondary,.75);
$success-75: rgba($success,.75);
$info-75: rgba($info,.75);
$warning-75: rgba($warning,.75);
$danger-75: rgba($danger,.75);
$light-75: rgba($light,.75);
$dark-75: rgba($dark,.75);

$primary-95: rgba($primary,.95);
$secondary-95: rgba($secondary,.95);
$success-95: rgba($success,.95);
$info-95: rgba($info,.95);
$warning-95: rgba($warning,.95);
$danger-95: rgba($danger,.95);
$light-95: rgba($light,.95);
$dark-95: rgba($dark,.95);

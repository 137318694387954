.bg-secondary-light {
  background: rgba($secondary, .15);
}

.bg-info-light {
  background: rgba($info, .15);
}

.bg-purple {
  background: rgba($purple, .15);
}

.text-purple {
  color: darken($purple, 25%) !important;
}

.bg-sundown {
  background: rgba($sundown, .25);
}

.text-sundown {
  color: darken($sundown, 33%) !important;
}

h1, h2, h3, h4, h5 {

}

section#hero {
  min-height: 560px;
  background: url('../images/retreat1.jpg') no-repeat center center;
  background-size: cover
}

.hero-inner {
  min-width: auto;
  max-width: 600px;
}

section#timings {
  min-height: 1024px;
  background: url('../images/retreat2.jpg') no-repeat center center;
  background-size: cover;
}

.timings-inner {
  min-width: auto;
  max-width: 720px;
}

.navbar-brand {
  font-size: 1rem !important;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.5);
}

.img-carmen {
  height: 280px;
  width: 280px;
  background: url('../images/carmen.jpg') no-repeat center center;
  background-size: cover;
}

@media (min-width: 576px) {
  section#timings {
    min-height: 780px;
  }
}

@media (min-width: 768px) {
  section#timings {
    min-height: 740px;
  }
}

@media (min-width: 992px) {
  section#timings {
    min-height: 680px;
  }
}

@media (min-width: 1200px) {
  section#timings {
    min-height: 660px;
  }
}

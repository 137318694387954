.navbar-header {
  float: none;
}

.modal {
  opacity: .85;
}

.modal-backdrop {
  &.in, &.show {
    opacity: 1;
  }
}

.modal, .modal-backdrop {
  background-color: $body-bg !important;
}

.modal-fullscreen-menu {

  .btn-close {
    color: $body-color !important;
    opacity: 1;
    padding: .75em;
    position: absolute;
    top: .5em;
    right: .5em;
    z-index: 1;
    font-size: $modal-fullscreen-font-size;
  }

  .modal-dialog {
    margin: 0 auto;
    width: 100%;
    max-width: 768px;
    display: flex;
    height: 100%;
    align-items: center;
    pointer-events: auto !important;
  }

  .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .list-group {
    z-index: 1;
    text-align: center;
    margin: 0 auto;
    width: 100%;

    a {
      font-weight: 200;
      letter-spacing: 0.05em;
      border: none;
      transition: all 0.25s ease;
      background-color: transparent;
      color: $body-color;
      padding: 7.5vh 0;
      height: $modal-fullscreen-item-height;
      font-size: $modal-fullscreen-font-size;
      line-height: 0;
      &:hover {
        color: $body-color !important;
      }
    }
  }

}

body {
  &.adjust-color-day {
    .modal, .modal-backdrop {
      background-color: $body-bg !important;
    }

    .modal-fullscreen-menu {
      .btn-close {
        color: $body-color;
        text-shadow: 0 1px 0 #000;
      }

      .list-group {
        color: $body-color !important;
        a {
          color: $body-color !important;
          &:hover {
            color: $body-color !important;
          }
        }
      }
    }
  }

  &.adjust-color-night {
    .modal, .modal-backdrop {
      background-color: $body-night-bg !important;
    }

    .modal-fullscreen-menu {
      .btn-close {
        color: $body-night-color;
        text-shadow: 0 1px 0 #fff;
      }

      .list-group {
        color: $body-night-color  !important;
        a {
          color: $body-night-color  !important;
          &:hover {
            color: $body-night-color !important;
          }
        }
      }
    }
  }
}
.footer {
  width: 100%;
  /*height: 200px;*/
  /*line-height: 60px;*/
  background: lighten($light, 15%);
}

.footer a {
  text-decoration: none !important;
}


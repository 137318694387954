@import "colors/bootstrap-default";
@import "colors/adjust";
@import "colors/override-susanne-schuetze";
@import "colors/alpha-channel";
@import "variables";

@import "~bootstrap/scss/bootstrap";

// customized bootstrap
@import "bootstrap/alerts";
@import "bootstrap/buttons";
@import "bootstrap/footer";
@import "bootstrap/quote-cards";

@import "bootstrap/form-init";
@import "bootstrap/forms";

@import "bootstrap-icons";

// fontawesome
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fontsource/open-sans/latin-400.css";
@import "@fontsource/open-sans/latin-600.css";
@import "fonts";

@import "layout";
@import "content";
@import "retreat";

@import "custom/cookie-consent";
@import "custom/bootstrap-toc";

@import "navigation/base";
@import "navigation/modal-fullscreen";
$sticky-top-navigation-height: 56px;
$sticky-top-anchor-margin: -56px;

$modal-fullscreen-font-size : 3.5vh;
$modal-fullscreen-item-height : 5vh;

$cookie-alert-outline: lighten($light, 25%);
$cookie-alert-border-top: $white;
$cookie-alert-gradient-top: lighten($light, 25%);
$cookie-alert-gradient-bottom: lighten($light, 15%);

$offcanvas-vertical-height: auto;
$offcanvas-padding-y: 1.25rem;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1140px
        /* xxl: 1320px */
);

/**
 * Bootstrap Vanilla TOC
 *
 * inspired by http://afeld.github.io/bootstrap-toc/)
 **/

[data-toggle="toc"] {
  top: 56px !important;
  .nav > li > a {
    display: block;
    padding: 0.25rem 1.25rem;
    font-size: 0.8125rem;
    font-weight: 500;
    //color: $primary;
    &:hover,
    &:focus {
      padding-left: 1.1875rem;
      color: mix($primary, $secondary);
      text-decoration: none;
      background-color: transparent;
      border-left: 1px solid mix($primary, $secondary);
    }
  }

  .nav-link {
    color: $secondary;
    &:hover {
      color: mix($primary, $secondary);
    }
    &.active,
    &:hover.active,
    &:focus.active {
      padding-left: 1.125rem;
      font-weight: bold;
      color: mix($primary, $secondary);
      background-color: transparent;
      border-left: 2px solid mix($primary, $secondary);
    }
    &.active {
      color: $primary;
      border-left: 2px solid $primary;
    }
  }

  /* Nav: second level (shown on .active) */
  .nav-link + ul {
    display: none; /* Hide by default, but at >768px, show it */
    padding-bottom: 10px;
  }

  .nav .nav > li > a {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 30px;
    font-size: 12px;
    font-weight: normal;
    &:hover,
    &:focus {
      padding-left: 29px;
    }
    &.active,
    &:hover.active,
    &:focus.active {
      padding-left: 28px;
      font-weight: 500;
    }
  }

  .nav-link.active + ul {
    display: block;
  }
}

#toc-sections {
  section[id]::before {
    content: '';
    display: block;
    height: 56px;
    margin-top: -56px;
    visibility: hidden;
  }

  section[id] {
    margin-top: 4rem;
    margin-bottom: 4rem;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 25vh;
    }
    & > section[id] {
      margin-top: 2rem;
      margin-bottom: 2rem;
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    h1, h2, h3, h4, h5 {
      margin-top: 4rem;
      margin-bottom: 1rem;
      &:first-of-type {
        margin-top: 0;
      }
    }
    p {
      margin-bottom: 2rem;
    }
  }
}

.alert-primary {
  background: $primary-35;
  border-color: $primary-55;
  color: darken($primary, 15%);
  .alert-link {
    color: darken($primary, 15%);
  }
  hr {
    border-top: 1px solid $primary-75;
  }
}

.alert-secondary {
  background: $secondary-35;
  border-color: $secondary-55;
  color: darken($secondary, 15%);
  .alert-link {
    color: darken($secondary, 15%);
  }
  hr {
    border-top: 1px solid $secondary-75;
  }
}

.alert-success {
  background: $success-35;
  border-color: $success-55;
  color: darken($success, 15%);
  .alert-link {
    color: darken($success, 15%);
  }
  hr {
    border-top: 1px solid $success-75;
  }
}

.alert-info {
  background: $info-35;
  border-color: $info-55;
  color: darken($info, 15%);
  .alert-link {
    color: darken($info, 15%);
  }
  hr {
    border-top: 1px solid $info-75;
  }
}

.alert-warning {
  background: $warning-35;
  border-color: $warning-55;
  color: darken($warning, 15%);
  .alert-link {
    color: darken($warning, 15%);
  }
  hr {
    border-top: 1px solid $warning-75;
  }
}

.alert-danger {
  background: $danger-35;
  border-color: $danger-55;
  color: darken($danger, 15%);
  .alert-link {
    color: darken($danger, 15%);
  }
  hr {
    border-top: 1px solid $danger-75;
  }
}

.alert-light {
  background: $light;
  border-color: darken($light, 15%);
  color: darken($light, 65%);
  .alert-link {
    color: darken($light, 65%);
  }
  hr {
    border-top: 1px solid $light-35;
  }
}

.alert-dark {
  background: $dark-35;
  border-color: $dark-55;
  color: darken($dark, 15%);
  .alert-link {
    color: darken($dark, 15%);
  }
  hr {
    border-top: 1px solid $dark-75;
  }
}


.adjust-color-night {

  .alert-primary {
    color: lighten($primary, 15%);
    .alert-link {
      color: lighten($primary, 15%);
    }
  }

  .alert-secondary {
    color: lighten($secondary, 15%);
    .alert-link {
      color: lighten($secondary, 15%);
    }
  }

  .alert-success {
    color: lighten($success, 15%);
    .alert-link {
      color: lighten($success, 15%);
    }
  }

  .alert-info {
    color: lighten($info, 15%);
    .alert-link {
      color: lighten($info, 15%);
    }
  }

  .alert-warning {
    color: lighten($warning, 15%);
    .alert-link {
      color: lighten($warning, 15%);
    }
  }

  .alert-danger {
    color: lighten($danger, 15%);
    .alert-link {
      color: lighten($danger, 15%);
    }
    hr {
      border-top: 1px solid $danger-75;
    }
  }

  .alert-light {
    background: $light-35;
    border-color: $light-55;
    color: lighten($light, 15%);
    .alert-link {
      color: lighten($light, 15%);
    }
    hr {
      border-top: 1px solid $light-75;
    }
  }

  .alert-dark {
    background: $dark-35;
    border-color: $dark-55;
    color: lighten($dark, 15%);
    .alert-link {
      color: lighten($dark, 15%);
    }
    hr {
      border-top: 1px solid $dark-75;
    }
  }

  .alert-dismissible .btn-close:hover {
    color: inherit !important;
  }


}